import type { Options } from 'focus-trap';
import { useEffect } from 'react';

const trappedDialogs = new Set();

// we are interested in external exponea dialogs only
// reach dialogs have focus trap build-in
const dialogSelector = '[role="dialog"]:not([data-reach-dialog-content])';

const log = (message: string, element?: HTMLElement) =>
  // eslint-disable-next-line no-console
  console.info.apply(undefined, ['Focus trap:', message, element].filter(Boolean));

const onNodeRemove = (element: HTMLElement, callback?: () => void) => {
  new MutationObserver((mutations, observer) => {
    if (!document.body.contains(element)) {
      trappedDialogs.delete(element);
      observer.disconnect();
      callback?.();
      log('deactivated for', element);
    }
  }).observe(document.body, { childList: true, subtree: true });
};

const activateFocusTrap = async (node: HTMLElement) => {
  if (trappedDialogs.has(node)) {
    return;
  }

  trappedDialogs.add(node);
  log('activated for', node);

  const focusTrapOptions: Options = {
    checkCanFocusTrap: trapContainers => {
      const results = trapContainers.map(
        trapContainer =>
          new Promise<void>(resolve => {
            const firstTabbableElement = trapContainer.querySelector('[tabindex="0"]');

            if (firstTabbableElement) {
              const interval = setInterval(() => {
                if (getComputedStyle(firstTabbableElement).visibility !== 'hidden') {
                  resolve();
                  clearInterval(interval);
                }
              }, 5);
            }
          })
      );

      return Promise.all(results) as unknown as Promise<void>;
    },
  };

  const { createFocusTrap } = await import('focus-trap');

  const focusTrap = createFocusTrap(node, focusTrapOptions);

  focusTrap.activate();
  onNodeRemove(node, focusTrap.deactivate);
};

const checkNewNodes: globalThis.MutationCallback = mutations => {
  mutations.forEach(mutation => {
    mutation.addedNodes.forEach(node => {
      if (!(node instanceof HTMLElement)) {
        return;
      }

      const nodeToTrap = node.matches(dialogSelector)
        ? node
        : node.querySelector<HTMLElement>(dialogSelector);

      if (nodeToTrap) {
        activateFocusTrap(nodeToTrap);
      }
    });
  });
};

/**
 * Observer to focus trap external dialogs injected by exponea
 */
export const useExternalDialogFocusTrap = () => {
  useEffect(() => {
    log('Observer init');

    const observer = new MutationObserver(checkNewNodes);

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      characterDataOldValue: true,
      attributeFilter: ['role'],
    });

    return () => {
      observer.disconnect();
      log('Observer disconnected');
    };
  }, []);
};
