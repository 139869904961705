/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactNode } from 'react';
import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import type { Maybe } from 'graphql/jsutils/Maybe';
import type { StyleVariant } from '../../graphql/codegen';
import { useStyleVariantsQuery } from '../../graphql/codegen';
import { usePDPContext } from '../static/PDPContext';
import { createQueryParams } from '../../parsers';
import { useAppContext } from '../static/AppContext';
import { styleVariantsLimitForSSR } from '../../constants';
import type { ProductVideo as ProductVideoSchema } from '../../../amplienceTypes/schemas/imported/product-detail-info-schema';

export interface ProductVideo {
  content: ProductVideoSchema;
  [k: string]: unknown;
}

type GalleryContextType = {
  galleryZoomIn: boolean;
  setGalleryZoomIn: (galleryZoomIn: boolean) => void;
  styleVariantsSelectedIndex: number;
  productStyleVariantIndex: number;
  selectColorCarouselIndex: (styleVariantsSelectedIndex: number) => void;
  styleVariants: Maybe<StyleVariant>[] | null | undefined;
  setStyleVariants: (styleVariants: Maybe<StyleVariant>[] | null | undefined) => void;
};

const GalleryContext = createContext<GalleryContextType>({
  galleryZoomIn: false,
  setGalleryZoomIn: () => {},
  styleVariantsSelectedIndex: -1,
  productStyleVariantIndex: -1,
  selectColorCarouselIndex: () => {},
  styleVariants: null,
  setStyleVariants: () => {},
});

export const useGalleryContext = () => useContext(GalleryContext);

export const GalleryContextProvider = ({ children }: { children: ReactNode }) => {
  const [galleryZoomIn, setGalleryZoomIn] = useState<boolean>(false);

  const {
    product: { availableStyleVariantsSorted, code, baseProduct },
  } = usePDPContext();
  const { locale } = useAppContext();

  const [styleVariants, setStyleVariants] = useState<Maybe<StyleVariant>[] | null | undefined>(
    availableStyleVariantsSorted
  );

  const { baseSiteId } = createQueryParams(locale);
  const { loading, error, data } = useStyleVariantsQuery({
    variables: {
      baseSiteId,
      locale,
      code,
    },
    skip: availableStyleVariantsSorted?.length !== styleVariantsLimitForSSR,
    ssr: false,
  });
  const productStyleVariantIndex =
    styleVariants?.findIndex(styleVariant => {
      if (baseProduct) {
        return styleVariant?.code === baseProduct;
      }

      if (code) {
        return styleVariant?.code === code.split('-').slice(0, 3).join('-').toUpperCase();
      }

      return false;
    }) ?? -1;

  const [styleVariantsSelectedIndex, selectColorCarouselIndex] =
    useState<number>(productStyleVariantIndex);

  useEffect(() => {
    if (!error && !loading && data) {
      setStyleVariants(data.product.availableStyleVariantsSorted);
    }
  }, [data, error, loading]);

  useEffect(() => {
    selectColorCarouselIndex(productStyleVariantIndex);
  }, [productStyleVariantIndex]);

  const value = useMemo(
    () => ({
      galleryZoomIn,
      setGalleryZoomIn,
      styleVariantsSelectedIndex,
      productStyleVariantIndex,
      selectColorCarouselIndex,
      styleVariants,
      setStyleVariants,
    }),
    [galleryZoomIn, styleVariants, styleVariantsSelectedIndex, productStyleVariantIndex]
  );

  return <GalleryContext.Provider value={value}>{children}</GalleryContext.Provider>;
};
