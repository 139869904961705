export const breakpoints = {
  xs: '320px',
  sm: '768px', // Mobile Breakpoint
  md: '960px',
  lg: '1024px',
  xl: '1250px', // Tablet Breakpoint
  xxl: '1680px',
  xxxl: '1920px',
} as const;

export type Breakpoint = keyof typeof breakpoints;
