// ENVIRONMENT variables
export enum Environments {
  DEV = 'DEV',
  TST = 'TST',
  ACC = 'ACC',
  PRD = 'PRD',
}

export const avalaraCountries = ['us', 'ca'];

// Country ISO Code
export const CHINA_CODE = 'cn';

// Exploding View
export const componentEasing = 'power3.out';
export const calloutEasing = 'power1.out';
export const calloutDuration = '200';
export const calloutLabelDelay = 0.1;
export const animationStartDelay = 0.5;

// Style Variants
export const styleVariantsLimitForSSR = 5;

// Size Selector Button
export type SizeButtonStyle = 'normal' | 'large' | 'fourA' | 'medium' | 'sizeRange';

// Custom events for qubit
export enum CustomEventTypes {
  PLP_STATE_UPDATE = 'plpStateUpdate',
  RECENTLY_VIEWED_PRODUCTS_LIST_UPDATED = 'recentlyViewedProductsListUpdated',
  CART_ADD_ITEM_SUCCESS = 'addToBagResponseSuccess',
  YMAL_EVENT = 'ymalEvent',
  MOBILE_FILTER_MENU_CLOSED = 'mobileFilterMenuClosed',
  IMAGE_VIEW_MODE = 'imageViewMode',
  PRODUCT_TILE_VARIANT_HOVERED = 'productTileVariantHovered',
  IMAGE_SWITCH_CONTEXT_REQUEST = 'imageSwitchContextRequest',
  IMAGE_SWITCH_CONTEXT_RESPONSE = 'imageSwitchContextResponse',
}

// Page types
export enum PageTypes {
  PRODUCT = 'product',
  HOME = 'home',
  CATEGORY = 'category',
  SEARCH = 'searchresults',
  OTHER = 'other',
  CAMPAIGN = 'campaign',
  STORIES = 'stories',
}

export const SPECIAL_MATERIAL_IMAGE = ['Jeans', 'O 5 pockets', 'Col jeans'];

export const FEATURE_TOGGLE = {
  // TODO: proper cleanup and file removal when it will become true
  // so basically when it will become true, the toggle won't be needed
  NEW_TILE_SIGNINGS: false,
};

export const NEWSLETTER_OVERLAY_PAGE_VIEWS = 'newsletterOverlayPageviews';
export const NEWSLETTER_OVERLAY_SHOWN = 'newsletterOverlayShown';
export const NEWSLETTER_OVERLAY_START_TIME = 'newsletterOverlayStartTime';
