import { createContext, useContext, useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import { useMediaQuery } from '../../dom';
import { media } from '../../../components/shared/core/styles';

export type Breakpoint = 'mobile' | 'tablet' | 'desktop' | undefined;
export type StrictBreakpoint = Exclude<Breakpoint, undefined>;

type MediaQueryContextType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  isPortrait: boolean;
  isLandscape: boolean;
  isMobileOrTablet: boolean;
  breakpoint: Breakpoint;
};

const MediaQueryContext = createContext<MediaQueryContextType>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isLargeDesktop: false,
  isPortrait: false,
  isLandscape: false,
  isMobileOrTablet: false,
  breakpoint: undefined,
});

export const useMediaQueryContext = () => useContext(MediaQueryContext);

export const MediaQueryContextProvider = ({ children }: PropsWithChildren) => {
  const isMobile = useMediaQuery(media.lessThan('sm'));
  const isTablet = useMediaQuery(media.between('sm', 'lg'));
  const isDesktop = useMediaQuery(media.greaterThan('lg'));
  const isLargeDesktop = useMediaQuery(media.greaterThan('xl'));
  const isPortrait = useMediaQuery(media.portrait);
  const isLandscape = !isPortrait;
  const isMobileOrTablet = isMobile || isTablet;

  let breakpoint: Breakpoint;

  switch (true) {
    case isMobile: {
      breakpoint = 'mobile';
      break;
    }
    case isTablet: {
      breakpoint = 'tablet';
      break;
    }
    case isDesktop: {
      breakpoint = 'desktop';
      break;
    }
    default: {
      breakpoint = undefined;
    }
  }

  const value = useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktop,
      isLargeDesktop,
      isPortrait,
      isLandscape,
      isMobileOrTablet,
      breakpoint,
    }),
    [
      isMobile,
      isTablet,
      isDesktop,
      isLargeDesktop,
      isPortrait,
      isLandscape,
      isMobileOrTablet,
      breakpoint,
    ]
  );

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>;
};
