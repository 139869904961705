import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { PAGE_VIEWS } from '../constants/localStorageKeys';
import { useEffectOnce } from './index';

export const getCurrentPageView = () => parseInt(localStorage.getItem(PAGE_VIEWS) || '', 10) || 0;

/**
 * should run only one instance
 */
export const usePageviewCount = () => {
  const router = useRouter();

  const onPageLoad = useCallback(() => {
    let pageViewCount = getCurrentPageView();

    pageViewCount += 1;
    localStorage.setItem(PAGE_VIEWS, `${pageViewCount}`);
  }, []);

  useEffect(() => {
    if (!router.isReady) {
      return () => {};
    }

    router.events.on('routeChangeComplete', onPageLoad);

    return () => {
      router.events.off('routeChangeComplete', onPageLoad);
    };
  }, [router, onPageLoad]);

  useEffectOnce(() => {
    onPageLoad();
  });
};
