import { breakpoints } from './breakpoints';
import type { Breakpoint } from './breakpoints';

function lessThan(bp: Breakpoint): string {
  return `(max-width: calc(${breakpoints[bp]} - 1px))`;
}

function greaterThan(bp: Breakpoint): string {
  return `(min-width: ${breakpoints[bp]})`;
}

function between(minBp: Breakpoint, maxBp: Breakpoint): string {
  return `${greaterThan(minBp)} and ${lessThan(maxBp)}`;
}

const canHover = '(hover: hover)' as const;
const noHover = '(hover: none)' as const;

const finePointer = '(pointer: fine)' as const;
const coarsePointer = '(pointer: coarse)' as const;

const noMotionPreference = '(prefers-reduced-motion: no-preference)' as const;

const portrait = '(orientation: portrait)' as const;

export const media = {
  lessThan,
  greaterThan,
  between,
  canHover,
  noHover,
  noMotionPreference,
  portrait,
  finePointer,
  coarsePointer,
};
