export const spacing = {
  /** spacing: `4px` */
  XXXXS: '4px',
  /** spacing: `8px` */
  XXXS: '8px',
  /** spacing: `12px` */
  XXS: '12px',
  /** spacing: `16px` */
  XS: '16px',
  /** spacing: `24px` */
  S: '24px',
  /** spacing: `32px` */
  M: '32px',
  /** spacing: `40px` */
  L: '40px',
  /** spacing: `56px` */
  XL: '56px',
  /** spacing: `64px` */
  XXL: '64px',
  /** spacing: `72px` */
  XXXL: '72px',
  /** spacing: `120px` */
  XXXXL: '120px',
  /** spacing: `160px` */
  XXXXXL: '160px',
  /** spacing: `240px` */
  XXXXXXL: '240px',
};
