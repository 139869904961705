import type { NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
const isBuildPhase = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;

const cache = new InMemoryCache({
  resultCacheMaxSize: 10_000,
  resultCaching: isBuildPhase || typeof window !== 'undefined',
  typePolicies: {
    User: {
      keyFields: false,
    },
    Subscription: {
      keyFields: false,
    },
    CartMutation: {
      keyFields: false,
    },
    CommonData: {
      keyFields: false,
    },
    Cart: {
      keyFields: false,
    },
    Wishlist: {
      keyFields: false,
    },
    CartCustomer: {
      keyFields: false,
    },
    ProductDynamicData: {
      keyFields: false,
    },
    Recommendations: {
      keyFields: false,
    },
    VoucherApplyResult: {
      keyFields: false,
    },
    ExponeaCustomerAttributesResponse: {
      keyFields: false,
    },
  },
});

if (!isBuildPhase) {
  setInterval(() => {
    cache.gc({
      resetResultCache: true,
      resetResultIdentities: true,
    });
    cache.reset({ discardWatches: true });
  }, 1_000 * 60 * 7);
}

function createClient() {
  return new ApolloClient<NormalizedCacheObject>({
    // forcefully sets watchQuery fetchPolicy to 'cache-first' when 'network-only' or 'cache-and-network'
    // forcefully sets query fetchPolicy to 'cache-first'
    ssrMode: isBuildPhase || typeof window === 'undefined',
    uri:
      (typeof window === 'undefined'
        ? process.env.GRAPHQL_ENDPOINT_SERVER_SIDE
        : process.env.GRAPHQL_ENDPOINT_CLIENT_SIDE) || 'http://localhost:5000/',
    credentials: 'include',
    // FS-1307 help to identify storefront traffic to /hub
    headers: {
      'x-gstar-fs': '66cc56d9ca18dd906749753179bef439',
      'x-gstar-fs-app': 'storefront',
    },
    name: 'storefront',
    version: '1.0',
    cache,
    defaultOptions: {
      mutate: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: isBuildPhase ? 'cache-first' : 'cache-and-network',
        errorPolicy: 'all',
        nextFetchPolicy(currentFetchPolicy) {
          if (currentFetchPolicy === 'network-only' || currentFetchPolicy === 'cache-and-network') {
            return 'cache-first';
          }

          return currentFetchPolicy;
        },
        refetchWritePolicy: 'overwrite',
      },
    },
  });
}

function getClient(): ApolloClient<NormalizedCacheObject> {
  // during build phase and client side we want to reuse
  // the apolloClient instance if present
  if (isBuildPhase || typeof window !== 'undefined') {
    if (!apolloClient) {
      apolloClient = createClient();
    }

    return apolloClient;
  }

  // for SSR we want a fresh client for each request
  // as per https://github.com/apollographql/apollo-client/issues/7942#issuecomment-812540838
  return createClient();
}

export { getClient };
