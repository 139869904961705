export const timeOffsets = {
  'UTC-8 (PT - US west)': '-0800',
  'UTC-5 (ET - US east)': '-0500',
  'UTC (GMT, WET)': '+0000',
  'UTC+1 (CET, WEST)': '+0100',
  'UTC+2 (CEST)': '+0200',
  'UTC+3 (EET)': '+0300',
  'UTC+9 (Japan)': '+0900',
};

// Header USP List
export const USP_ANIMATION_DURATION = 600;
export const USP_MESSAGE_DISPLAY_TIME = 4000;
