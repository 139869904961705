import type { ReactElement, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { media } from './media';
import type { Breakpoint } from './index';
import { useGalleryContext } from '../../../../utilities/context/dynamic/GalleryContext';

type JustifyType =
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'inherit'
  | 'initial'
  | 'left'
  | 'normal'
  | 'revert'
  | 'right'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'start'
  | 'stretch'
  | 'unset';

type AlignType =
  | 'baseline'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'inherit'
  | 'initial'
  | 'normal'
  | 'revert'
  | 'self-end'
  | 'self-start'
  | 'start'
  | 'stretch'
  | 'unset';

type DirectionType = 'column' | 'row';

type ColumnRangeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface ContainerProps {
  className?: string;
}

export interface RowProps {
  justify?: JustifyType;
  align?: AlignType;
  direction?: DirectionType;
  className?: string;
}

interface StyledRowProps {
  $justify?: JustifyType;
  $align?: AlignType;
  $direction?: DirectionType;
}

export interface ColumnProps {
  xs?: ColumnRangeType;
  sm?: ColumnRangeType;
  md?: ColumnRangeType;
  lg?: ColumnRangeType;
  xl?: ColumnRangeType;
  className?: string;
  displayType?: string;
}

interface StyledColumnProps {
  $xs?: ColumnRangeType;
  $sm?: ColumnRangeType;
  $md?: ColumnRangeType;
  $lg?: ColumnRangeType;
  $xl?: ColumnRangeType;
  $displayType?: string;
  $isSticky?: boolean;
  $zIndex?: number;
  $isFullViewportHeight?: boolean;
}

const maxColumns = 12;

const columnStyle = ($breakpoint: Breakpoint, $columns: ColumnRangeType) => css`
  @media ${media.greaterThan($breakpoint)} {
    flex: 0 0 ${(100 / maxColumns) * $columns}%;
    max-width: ${(100 / maxColumns) * $columns}%;
  }
`;

const S = {
  Container: styled.div``,

  Row: styled.div<StyledRowProps>`
    display: flex;
    flex: 1 1 auto;
    align-items: ${({ $align }) => $align};
    justify-content: ${({ $justify }) => $justify};
    flex-flow: ${({ $direction }) => $direction} wrap;
    min-height: 1px;
  `,

  Column: styled.div<StyledColumnProps>`
    flex: 0 0 100%;
    max-width: 100%;
    z-index: ${({ $zIndex }) => (!Number.isNaN($zIndex) ? $zIndex : 'initial')};

    ${({ $displayType }) => css`
      display: ${$displayType || 'block'};
      pointer-events: ${$displayType === 'flex' ? 'none' : 'initial'};
      ${$displayType === 'flex' ? 'flex-direction: column;' : ''}
      ${$displayType === 'flex' ? 'align-items: flex-end;' : ''}
    `}

    ${({ $isSticky }) => css`
      position: ${$isSticky ? 'sticky' : 'relative'};
      top: ${$isSticky ? 'var(--header-height)' : 'initial'};
      margin-top: ${$isSticky ? '-100vh' : 'initial'};
    `}

    ${({ $isFullViewportHeight }) =>
      $isFullViewportHeight &&
      css`
        @media ${media.greaterThan('lg')} {
          min-height: calc(100vh - var(--header-height));
        }
      `}

    @media ${media.lessThan('lg')} {
      display: block;
      pointer-events: initial;
      position: relative;
      top: initial;
      margin-top: initial;
    }

    ${({ $xs }) => $xs && columnStyle('xs', $xs)};
    ${({ $sm }) => $sm && columnStyle('sm', $sm)};
    ${({ $md }) => $md && columnStyle('md', $md)};
    ${({ $lg }) => $lg && columnStyle('lg', $lg)};
    ${({ $xl }) => $xl && columnStyle('xl', $xl)};
  `,
};

const Container = ({
  children,
  className = '',
}: ContainerProps & PropsWithChildren<unknown>): ReactElement => (
  <S.Container className={className}>{children}</S.Container>
);

const Row = ({
  children,
  justify = 'flex-start',
  align = 'flex-start',
  direction = 'row',
  className = '',
}: RowProps & PropsWithChildren<unknown>): ReactElement => (
  <S.Row $justify={justify} $align={align} $direction={direction} className={className}>
    {children}
  </S.Row>
);

const Column = ({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  displayType,
  className = '',
}: ColumnProps & PropsWithChildren<unknown>): ReactElement => {
  const { galleryZoomIn } = useGalleryContext();
  const isSticky = Boolean((children as ReactElement[])?.[0]?.props?.sticky);
  const hasProductAccordions = (children as ReactElement[])?.some(
    child => child?.props?.name === 'productAccordions'
  );

  return (
    <S.Column
      className={className}
      $xs={xs}
      $sm={sm}
      $md={md}
      $lg={lg}
      $xl={xl}
      $displayType={displayType}
      $isSticky={isSticky}
      $zIndex={isSticky && galleryZoomIn ? 1 : 0}
      $isFullViewportHeight={hasProductAccordions}
    >
      {children}
    </S.Column>
  );
};

export { Container, Row, Column };
