import type { AppProps } from 'next/app';
import { StyleSheetManager } from 'styled-components';
import { appWithTranslation } from 'next-i18next';
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import stylisLogicalPropsMiddleware from '@rocket.chat/stylis-logical-props-middleware';
import { GlobalStyle } from '../components/shared/core/styles/global';
import { getClient } from '../utilities/graphql/apolloClient';
import { AppProvider } from '../utilities/context/static/AppContext';
import { useLangObserver } from '../utilities/hooks';
import { toHtmlLang } from '../utilities/parsers';
import { MediaQueryContextProvider } from '../utilities/context/dynamic/MediaQueryContext';
import { useExternalDialogFocusTrap } from '../utilities/hooks/useExternalDialogFocusTrap';
import type { PageProperties } from '../amplienceTypes/schemas/imported/generic-layout-slot-schema';
import { usePageviewCount } from '../utilities/hooks/usePageviewCount';

/* eslint-disable import/no-extraneous-dependencies */
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  (async () => {
    const React = await import('react');
    const ReactDOM = await import('react-dom');
    const axe = await import('@axe-core/react');
    const { config, options } = await import('../config/axe');

    axe.default(React.default, ReactDOM.default, 1000, {
      ...config,
      ...options,
    });
  })();
}

export type VendorEvent = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type AppSettingsObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

declare global {
  interface Window {
    dataLayer: VendorEvent[];
    uv: VendorEvent;
    _uxa: string[][];
    embedded_svc: VendorEvent;
    exponea: VendorEvent;
    fitAnalyticsData: VendorEvent | null;
    _fitAnalytics: VendorEvent | null;
    __fitAnalytics__: VendorEvent | null;
    AppSettings: AppSettingsObject;
    ampliencePageProperties: PageProperties | undefined;
    AudioEye: VendorEvent;
  }

  // Redeclaring Navigator interface because of userAgentData
  // From this repo https://github.com/lukewarlow/user-agent-data-types/blob/master/index.d.ts

  interface NavigatorUABrandVersion {
    readonly brand: string;
    readonly version: string;
  }

  interface UADataValues {
    readonly brands?: NavigatorUABrandVersion[];
    readonly mobile?: boolean;
    readonly platform?: string;
    readonly architecture?: string;
    readonly bitness?: string;
    readonly model?: string;
    readonly platformVersion?: string;
    /** @deprecated in favour of fullVersionList */
    readonly uaFullVersion?: string;
    readonly fullVersionList?: NavigatorUABrandVersion[];
    readonly wow64?: boolean;
  }

  interface UALowEntropyJSON {
    readonly brands: NavigatorUABrandVersion[];
    readonly mobile: boolean;
    readonly platform: string;
  }

  interface NavigatorUAData extends UALowEntropyJSON {
    getHighEntropyValues(hints: string[]): Promise<UADataValues>;
    toJSON(): UALowEntropyJSON;
  }

  interface Navigator {
    readonly userAgentData?: NavigatorUAData;
  }
}

function MyApp({ Component, pageProps, router }: AppProps) {
  const apolloClient = getClient();
  const { locale } = router;

  useLangObserver(toHtmlLang(locale || 'en'));
  useExternalDialogFocusTrap();
  usePageviewCount();

  return (
    <AppProvider locale={locale || ''}>
      <ApolloProvider client={apolloClient}>
        <Head>
          {/* This meta tag is required for position css to work properly in mobile browsers */}
          <meta name="viewport" content="width=device-width,initial-scale=1" />
        </Head>
        <GlobalStyle />
        <StyleSheetManager enableVendorPrefixes stylisPlugins={[stylisLogicalPropsMiddleware]}>
          <MediaQueryContextProvider>
            <Component {...pageProps} />
          </MediaQueryContextProvider>
        </StyleSheetManager>
      </ApolloProvider>
    </AppProvider>
  );
}

export default appWithTranslation(MyApp);
