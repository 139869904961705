export const schemaType = {
  organization: 'Organization',
  postalAddress: 'PostalAddress',
  product: 'Product',
  brand: 'Brand',
  offer: 'Offer',
  breadcrumbList: 'BreadcrumbList',
  listItem: 'ListItem',
  videoObject: 'VideoObject',
} as const;

export const SCHEMA_CONTEXT = 'https://schema.org' as const;
