import type { PropsWithChildren } from 'react';
import { createContext, useContext, useRef } from 'react';
import type { Product } from '../../graphql/codegen';

type PDPContextType = {
  product: Product;
};

const PDPContext = createContext<PDPContextType>({
  product: {},
});

export const usePDPContext = () => useContext(PDPContext);

export const PDPContextProvider = ({ product, children }: PropsWithChildren<PDPContextType>) => {
  const value = useRef({
    product,
  });

  return <PDPContext.Provider value={value.current}>{children}</PDPContext.Provider>;
};
